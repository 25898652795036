<template>
  <en-drawer :model-value="modelValue" :title="`${form.data.id ? '编辑' : '新建'}保单`" @close="$emit('update:model-value', false)">
    <en-form :model="form.data" :rules="form.rules" :ref="setRef('form')" :loading="form.loading">
      <en-form-item label="车牌" prop="vehicle.id">
        <select-maintain
          v-model="form.data.vehicle"
          :ajax="{
            action: 'GET /enocloud/common/vehicle',
            params: (params, value) =>
              (params.payload = {
                quickSearch: value,
                rsexp: 'id,plateNo,owner[id,name]'
              })
          }"
          :props="{ label: 'plateNo', value: '' }"
          value-key="id"
          remote
          :disabled="form.data.status?.code === 'S'"
          class="w-full"
          @change="form.vehicle.change"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="投保身份证" prop="holderIdentity">
        <en-input v-model="form.data.holderIdentity" :disabled="form.data.status?.code === 'S'"></en-input>
      </en-form-item>
      <en-form-item label="被保身份证" prop="recognizeeIdentity">
        <en-input v-model="form.data.recognizeeIdentity" :disabled="form.data.status?.code === 'S'"></en-input>
      </en-form-item>
      <en-form-item label="交险保险公司" prop="compulsoryInsuranceCompany.id">
        <select-maintain
          v-model="form.data.compulsoryInsuranceCompany"
          :ajax="{
            action: 'GET /enocloud/common/customer',
            params: (params, value) => (params.payload = { name: value, type: 'I' })
          }"
          :props="{ label: 'name', value: '' }"
          value-key="id"
          remote
          :disabled="form.data.status?.code === 'S'"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="商险保险公司" prop="commercialInsuranceCompany.id">
        <select-maintain
          v-model="form.data.commercialInsuranceCompany"
          :ajax="{
            action: 'GET /enocloud/common/customer',
            params: (params, value) => (params.payload = { name: value, type: 'I' })
          }"
          :props="{ label: 'name', value: '' }"
          value-key="id"
          remote
          :disabled="form.data.status?.code === 'S'"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="销售人员" prop="salesman.id">
        <select-maintain
          v-model="form.data.salesman"
          :ajax="{
            action: 'GET /enocloud/common/user',
            params: (params, value) => (params.payload = { name: value })
          }"
          :props="{ label: 'name', value: '' }"
          value-key="id"
          remote
          :disabled="form.data.status?.code === 'S'"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="投保人">
        <en-input v-model="form.data.holderName" :disabled="form.data.status?.code === 'S'"></en-input>
      </en-form-item>
      <en-form-item label="投保人电话">
        <en-input v-model="form.data.holderPhone" :disabled="form.data.status?.code === 'S'"></en-input>
      </en-form-item>
      <en-form-item label="被保人">
        <en-input v-model="form.data.recognizeeName" :disabled="form.data.status?.code === 'S'"></en-input>
      </en-form-item>
      <en-form-item label="被保人电话">
        <en-input v-model="form.data.recognizeePhone" :disabled="form.data.status?.code === 'S'"></en-input>
      </en-form-item>
      <en-form-item label="交险单号">
        <en-input v-model="form.data.compulsoryInsuranceSerialNo" :disabled="form.data.status?.code === 'S'" class="w-full"></en-input>
      </en-form-item>
      <en-form-item label="交险投保日期">
        <en-date-picker v-model="form.data.compulsoryInsuranceInsureDate" :disabled="form.data.status?.code === 'S'" class="w-full"></en-date-picker>
      </en-form-item>
      <en-form-item label="交险起止时间">
        <en-date-picker
          v-model:start="form.data.compulsoryInsuranceStartDate"
          v-model:end="form.data.compulsoryInsuranceEndDate"
          type="daterange"
          :disabled="form.data.status?.code === 'S'"
        ></en-date-picker>
      </en-form-item>
      <en-form-item label="商险单号">
        <en-input v-model="form.data.commercialInsuranceSerialNo" :disabled="form.data.status?.code === 'S'"></en-input>
      </en-form-item>
      <en-form-item label="商险投保日期">
        <en-date-picker v-model="form.data.commercialInsuranceInsureDate" :disabled="form.data.status?.code === 'S'" class="w-full"></en-date-picker>
      </en-form-item>
      <en-form-item label="商险起止时间">
        <en-date-picker
          v-model:start="form.data.commercialInsuranceStartDate"
          v-model:end="form.data.commercialInsuranceEndDate"
          type="daterange"
          :disabled="form.data.status?.code === 'S'"
        ></en-date-picker>
      </en-form-item>
      <en-form-item label="初次登记时间">
        <en-date-picker v-model="form.data.recordDate" :disabled="form.data.status?.code === 'S'" class="w-full"></en-date-picker>
      </en-form-item>
      <en-form-item label="备注">
        <en-input v-model="form.data.comment" :disabled="form.data.status?.code === 'S'"></en-input>
      </en-form-item>
      <en-form-item label="保单照片">
        <upload-maintain v-model="form.data.imgUrls" multiple :limit="2" dir="policy" :disabled="form.data.status?.code === 'S'"></upload-maintain>
      </en-form-item>
    </en-form>

    <template #footer>
      <en-button @click="footer.cancel.click">取消</en-button>
      <button-ajax :disabled="form.data.status?.code === 'S'" :method="footer.confirm.click">确定</button-ajax>
    </template>
  </en-drawer>
</template>

<script lang="ts">
export default factory({
  props: {
    modelValue: Boolean,
    data: Object as PropType<EnocloudServiceDefinitions['PolicyDto']>
  },

  emits: {
    'update:model-value': (value: boolean) => typeof value === 'boolean',
    confirm: (id: number | undefined) => ['number', 'undefinded'].includes(typeof id)
  },

  watch: {
    modelValue: {
      async handler() {
        this.form.init()
        if (this.modelValue) {
          this.form.data.id = this.data?.id
          if (this.form.data.id) this.form.get()
        }
      }
    }
  },

  config: {
    children: {
      form: {
        rules: {
          'vehicle.id': [{ required: true, message: '请选择车牌' }],
          holderIdentity: [{ required: true, message: '请填写投保身份证' }],
          recognizeeIdentity: [{ required: true, message: '请填写被保人身份证' }],
          'compulsoryInsuranceCompany.id': [{ required: true, message: '请选择交险保险公司' }],
          'commercialInsuranceCompany.id': [{ required: true, message: '请选择商险保险公司' }],
          'salesman.id': [{ required: true, message: '请选择销售人员' }]
        },
        ajax: {
          get: {
            action: 'GET /enocloud/policy/:policyId',
            data: 'object',
            loading: true,
            init: true,
            params(params) {
              params.paths = [this.form.data.id]
            }
          },
          submit: {
            action: 'POST /enocloud/policy',
            loading: true,
            validate: true,
            params(params) {
              params.payload = this.form.data
            }
          },
          update: {
            action: 'PUT /enocloud/policy',
            loading: true,
            validate: true,
            params(params) {
              params.payload = this.form.data
            }
          }
        },
        computed: {
          disabled() {
            return this.form.data.status?.code === 'A' || this.form.data.status?.code === 'S'
          }
        },
        children: {
          vehicle: {
            change() {
              this.form.data.customer = this.form.data.vehicle?.owner
            }
          }
        }
      },
      footer: {
        cancel: {
          click() {
            this.emit('update:model-value', false)
          }
        },
        confirm: {
          async click() {
            const res = await this.form[this.form.data.id ? 'update' : 'submit']()
            this.emit('update:model-value', false)
            this.emit('confirm', (res.data[0] as number | undefined) || this.form.data.id)
          }
        }
      }
    }
  }
})
</script>
